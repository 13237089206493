<template>
    <Box>
  
      <Row>
        <Column :width="15">
          <Box :key="redraw">
            <DetailsDisplayRow v-if="this.showDialog == 'detailsDialog'" :userId="this.selectedUserId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue" :isAdmin="isAdmin"></DetailsDisplayRow>
            <TimesheetListRow v-if="this.showDialog == 'timesheetsDialog'" :userId="this.selectedUserId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue" :isAdmin="isAdmin"></TimesheetListRow>
            <InspectionListRow v-if="this.showDialog == 'inspectionsDialog'" :userId="this.selectedUserId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue" :isAdmin="isAdmin"></InspectionListRow>
            <UpdateRow v-if="this.showDialog == 'updateDialog'" :userId="this.selectedUserId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue" :isAdmin="isAdmin" :showButtons="false"></UpdateRow>
            <AddRow v-if="this.showDialog == 'addDialog'" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue" :isAdmin="isAdmin" :showButtons="false"></AddRow>
            <InviteRow v-if="this.showDialog == 'inviteDialog'" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue" :isAdmin="isAdmin" :showButtons="false"></InviteRow>
            <WorkingDisplayRow v-if="this.showDialog == 'workingDialog'"></WorkingDisplayRow>
            <UserRemoveConfirmDialogRow v-if="this.showDialog == 'removeDialog'" :userId="this.selectedUserId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue" :isAdmin="isAdmin"></UserRemoveConfirmDialogRow>
            <ResendConfirmDialogRow v-if="this.showDialog == 'resendDialog'" :userId="this.selectedUserId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue" :isAdmin="isAdmin"></ResendConfirmDialogRow>
            <SendConfirmDialogRow v-if="this.showDialog == 'sendDialog'" :userId="this.selectedUserId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue" :isAdmin="isAdmin"></SendConfirmDialogRow>
            <RestoreConfirmDialogRow v-if="this.showDialog == 'restoreDialog'" :userId="this.selectedUserId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue" :isAdmin="isAdmin"></RestoreConfirmDialogRow>
            <PasswordResetConfirmDialogRow v-if="this.showDialog == 'passwordDialog'" :userId="this.selectedUserId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue" :isAdmin="isAdmin"></PasswordResetConfirmDialogRow>
            <Row v-for="(row, rowIndex) in viewData" :key="rowIndex" >
              <CellFactory 
                :rowData="row.data"
                :showMissingRequirements="showMissingRequirements"
                v-on:withValue="withValue"
                v-on:withButtonPressed="withButtonPressed"
                  />
            </Row>
          </Box>
        </Column>
      </Row>
  
    </Box>
    
  </template>
  
  <script>
  
import { mapActions, mapGetters } from 'vuex';
  
import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';
  
import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";
  
import ListViewModel from "./ListViewModel.js";
import ListViewUtils from "./ListViewUtils.js";
  
import CellFactory from "@/portals/shared/cell/CellFactory.vue";
import DetailsDisplayRow from './details/DetailsDisplayRow.vue';
import TimesheetListRow from './timesheet/TimesheetListRow.vue';
import InspectionListRow from './inspection/InspectionListRow.vue';
import AddRow    from './add/AddRow.vue';
import UpdateRow from './update/UpdateRow.vue';
import InviteRow from './invite/InviteRow.vue';
import WorkingDisplayRow from '@/portals/shared/views/working/WorkingDisplayRow.vue';
import UserRemoveConfirmDialogRow from "./remove/RemoveConfirmDialogRow.vue";
import PasswordResetConfirmDialogRow from "./reset/ResetConfirmDialogRow.vue";
import ResendConfirmDialogRow from './resend/ResendConfirmDialogRow.vue';
import SendConfirmDialogRow from './send/SendConfirmDialogRow.vue';
import RestoreConfirmDialogRow from './restore/RestoreConfirmDialogRow.vue';

export default {
    name: "employee-list-view",
    components: {
      Column, Box, Row,
      CellFactory,
      AddRow,
      UpdateRow,
      InviteRow,
      DetailsDisplayRow,
      TimesheetListRow,
      InspectionListRow,
      WorkingDisplayRow,
      UserRemoveConfirmDialogRow,
      PasswordResetConfirmDialogRow,
      ResendConfirmDialogRow,
      SendConfirmDialogRow,
      RestoreConfirmDialogRow,
    },
    props: {
      isAdmin: { type: Boolean, default: false },
    },
    data() {
      return {
        redraw: 1,
        
        MC: new MC(),
        Strings: StringUtils,
        Contents: ContentUtils,
        Consts: ConstUtils,
        
        CellFactory: CellFactory,
        
        viewModel: null,
        viewData: [],
        showMissingRequirements: false,
        showDialog: "",
        selectedUserId: "",

        ListViewModel: ListViewModel,
        ListViewUtils: ListViewUtils,
      };
    },
    computed: {
      ...mapGetters([
                     'auth_connected',
                     'auth_socket_status',
                     'signin_event',
                     'auth_client',
                     'domain',
                     ]),
    },
    watch: {
      auth_connected() {
        ConnectionUtils.sendSignInEvent(this);
      },
      auth_socket_status() {
        ConnectionUtils.displayStatus(this);
      },
      signin_event() {
        ConnectionUtils.createDomainSession(this);
      },
      $route (to, from){
        if (to && from) {
          if (to.query["action"] === "userDetails") {
            this.selectedUserId = to.query["userId"];
            this.showDialog = "detailsDialog";

          } else if (to.query["action"] === "userHours") {
            this.selectedUserId = to.query["userId"];
            this.showDialog = "timesheetsDialog";

          } else if (to.query["action"] === "userInspections") {
            this.selectedUserId = to.query["userId"];
            this.showDialog = "inspectionsDialog";

          } else if (to.query["action"] === "updateDialog") {
            this.selectedUserId = to.query["userId"];
            this.showDialog = "updateDialog";

          } else if (to.query["action"] === "removeDialog") {
            this.selectedUserId = to.query["userId"];
            this.showDialog = "removeDialog";

          } else if (to.query["action"] === "addDialog") {
            this.showDialog = "addDialog";

          } else if (to.query["action"] === "inviteDialog") {
            this.showDialog = "inviteDialog";

          } else if (to.query["action"] === "passwordDialog") {
            this.selectedUserId = to.query["userId"];
            this.showDialog = "passwordDialog";

          } else if (to.query["action"] === "sendDialog") {
            this.selectedUserId = to.query["userId"];
            this.showDialog = "sendDialog";

          } else if (to.query["action"] === "restoreDialog") {
            this.selectedUserId = to.query["userId"];
            this.showDialog = "restoreDialog";

          } else if (to.query["action"] === "resendDialog") {
            this.selectedUserId = to.query["userId"];
            this.showDialog = "resendDialog";

          } else if (to.query["action"] === "update") {
            this.selectedUserId = to.query["userId"];

          } else if (to.query["action"] === "email") {
            this.selectedUserId = to.query["userId"];
            this.showDialog = "emailDialog";

          } else if (to.query["action"] === "remarks") {
            this.selectedUserId = to.query["userId"];
            this.showDialog = "remarksDialog";

          } else {
            if (StringUtils.isNotEmpty(this.showDialog)) {
              this.showDialog = "";

            } else {
              this.reloadPage();
            }
          }
        }
      }
    },
    mounted: function () {
      this.viewModel = new this.ListViewModel(this);
      this.viewModel.start();
      ConnectionUtils.ensureConnection(this);
    },
    methods: {
      ...mapActions([
                     'AuthStorage_updateCompany',
                     'addListenerAction',
                     'removeListenerAction',
                     'updateDomain',
                     ]),
  
      start: function() {
        this.viewModel.withStateContentLoading();
        this.viewModel.clearAndBuildTableData();
        this.viewData = this.viewModel.viewData();
        this.paint();
        this.viewModel.loadEmployees();
      },
  
      displayProgress: function(msg) {
        this.callback(msg);
      },

      showDialogIfRequired: function() {
        var query = Object.assign({}, this.$route.query);
        this.selectedUserId = query["userId"];
        this.showDialog = query["action"];
      },

      callback: function(msg) {
        var row = this.viewModel.findRowProgress();
        if (row) {
          row.withMessage(msg);
        }
        this.paint();
      },
      
      model: function() {
        return this.viewModel;
      },
      
      withValue: function(valueData) {
        this.model().withValue(valueData);
        if (valueData["field"] === "PageSize") {
          this.model().withPageSize(valueData);
          this.addQueryParams(valueData, "pageSize");
        }
        if (valueData["field"] === "PageIndex") {
          this.addQueryParams(valueData, "page");
        }
        
        if (valueData["field"] === "Search") {
          this.model().withSearchText(valueData);
        }

        if (valueData["field"] === "Date") {
          this.addQueryParams(valueData, "time")
        }
      },
      
      urlQueryParams() {
        var query = Object.assign({}, this.$route.query);
        if (query == null) {
          query = {};
        }
        return query;
      },

      addQueryParams(valueData, key) {
        var query = this.urlQueryParams();
        this.addQueryParamsToRouter(query, valueData, key);
      },

      addQueryParamsToRouter(query, valueData, key) {
        query[key] = valueData["value"];
        if (key === "pageSize") {
          delete query.page
        }
        this.$router.push({ query });
      },

      withButtonPressed: function(buttonData) {
        this.model().withButtonPressed(buttonData);

        if (buttonData["field"] === "Details") {
          const query = Object.assign({}, this.$route.query);
          query["action"] = "userDetails";
          query["userId"] = this.Contents.baseEncode(buttonData["id"]);
          this.$router.push({ query });
        }
        
        if (buttonData["field"] === "UserUpdate") {
          const query = Object.assign({}, this.$route.query);
          query["action"] = "updateDialog";
          query["userId"] = this.Contents.baseEncode(buttonData["id"]);
          this.viewModel.pushToRouter(query);

          // const userId = buttonData["id"];
          // const user = this.domain.users().findById(userId);
          // const company = this.viewModel.company();
          // const employee = this.domain.employees().findForCompanyAndUser(company, user);
          // const employeeId = ContentUtils.encodeText(employee.id());
          // const path = StringUtils.format(ConstUtils.ROUTES.EMPLOYEE.EDIT_PATH, [this.viewModel.company().idEncoded(), employeeId]);
          // this.$router.push({ path });
        }

        if (buttonData["field"] === "Rebuild") {
          this.showDialog = "workingDialog";
        }

        if (buttonData["field"] === "Refresh") {
          this.reloadPage();
        }

        if (buttonData["field"] === "UserRemoveConfirm") {
          const query = Object.assign({}, this.$route.query);
          query["action"] = "removeDialog";
          query["userId"] = this.Contents.baseEncode(buttonData["id"]);
          this.$router.push({ query });
        }

        if (buttonData["field"] === "PasswordResetConfirm") {
          const query = Object.assign({}, this.$route.query);
          query["action"] = "passwordDialog";
          query["userId"] = this.Contents.baseEncode(buttonData["id"]);
          this.$router.push({ query });
        }

        if (buttonData["field"] === "Restore") {
          const query = Object.assign({}, this.$route.query);
          query["action"] = "restoreDialog";
          query["userId"] = this.Contents.baseEncode(buttonData["id"]);
          this.$router.push({ query });
        }

        if (buttonData["field"] === "Resend") {
          const query = Object.assign({}, this.$route.query);
          query["action"] = "resendDialog";
          query["userId"] = this.Contents.baseEncode(buttonData["id"]);
          this.$router.push({ query });
        }

        if (buttonData["field"] === "Send") {
          const query = Object.assign({}, this.$route.query);
          query["action"] = "sendDialog";
          query["userId"] = this.Contents.baseEncode(buttonData["id"]);
          this.$router.push({ query });
        }

        if (buttonData["field"] === "Invite") {
          // const path = StringUtils.format(ConstUtils.ROUTES.EMPLOYEE.INVITE_PATH, [this.viewModel.company().idEncoded()]);
          // this.$router.push({ path });
          const query = Object.assign({}, this.$route.query);
          query["action"] = "inviteDialog";
          this.$router.push({ query });
        }

        if (buttonData["field"] === "Add") {
          // const path = StringUtils.format(ConstUtils.ROUTES.EMPLOYEE.ADD_PATH, [this.viewModel.company().idEncoded()]);
          // this.$router.push({ path });
          const query = Object.assign({}, this.$route.query);
          query["action"] = "addDialog";
          this.$router.push({ query });
        }

        if (buttonData["field"] === "Close" || buttonData["field"] === "DetailsClose") {
          var query = Object.assign({}, this.$route.query);
          query = this.deleteDetails(query);
          this.$router.push({ query });
          if (buttonData["refresh"]) {
            this.reloadPage();
          }
        }

        if (buttonData["field"] === "Time") {
          this.addQueryParams(buttonData, "time");
        }

        if (buttonData["field"] === "Status") {
          this.addQueryParams(buttonData, "status");
        }

        if (buttonData["field"] === "Full") {
          const fullParams = {
            id: this.Contents.baseDecode(buttonData["id"]),
          };
          this.$router.push({
            name: ConstUtils.ROUTES.EMPLOYEE.DETAILS,
            params: fullParams,
          });
        }

        if (buttonData["field"] === "Hours") {
          const fullParams = {
            userId: this.Contents.baseEncode(buttonData["id"]),
          };
          let listPath = StringUtils.format(ConstUtils.ROUTES.TIMESHEET.LIST_PATH, [this.model().company().idEncoded()]);
          this.$router.push({
            path: listPath,
            query: fullParams,
          });
          // const query = Object.assign({}, this.$route.query);
          // query["action"] = "userHours";
          // query["userId"] = this.Contents.baseEncode(buttonData["id"]);
          // this.$router.push({ query });
        }

        if (buttonData["field"] === "Inspections") {
          const fullParams = {
            userId: this.Contents.baseEncode(buttonData["id"]),
          };
          let listPath = StringUtils.format(ConstUtils.ROUTES.INSPECTION.LIST_PATH, [this.model().company().idEncoded()]);
          this.$router.push({
            path: listPath,
            query: fullParams,
          });
          // const query = Object.assign({}, this.$route.query);
          // query["action"] = "userInspections";
          // query["userId"] = this.Contents.baseEncode(buttonData["id"]);
          // this.$router.push({ query });
        }

        if (buttonData["field"] === "NextPage") {
          const query = Object.assign({}, this.$route.query);
          if (query.page) {
            query.page = (Number(query.page) + 1) + "";
          } else {
            query.page = "2";
          }
          if (query.page <= this.model().pages().length) {
            this.$router.push({ query });
          }
        }

        if (buttonData["field"] === "PreviousPage") {
          const query = Object.assign({}, this.$route.query);
          if (query.page) {
            query.page = (Number(query.page) - 1) + "";
            if (query.page <= 0) {
              query.page = "1";
            }
            this.$router.push({ query });
          }
        }
        
        if (buttonData["field"] === "Search") {
          buttonData["value"] = this.model().searchText();
          const queryParams = this.urlQueryParams();
          delete queryParams["page"];
          this.addQueryParamsToRouter(queryParams, buttonData, "search");
        }

        if (buttonData["field"] === "Clear") {
          this.model().withSearchText("");
          buttonData["value"] = "";
          this.addQueryParams(buttonData, "search");
        }
      },
      
      deleteDetails(query) {
        delete query.action;
        delete query.userId;
        delete query.timesheetId;
        delete query.inspectionId;
        return query;
      },

      reloadPage: function() {
        this.viewModel.withStateContentLoading();
        this.viewModel.clearAndBuildTableData();
        this.viewModel.loadEmployees();
      },

      paint: function() {
        this.redraw++;
      },

    },
  }
</script>