<template>
  <InviteView 
    :userId="userId" 
    :employeeId="employeeId" 
    @click="withButtonPressed" 
    @withButtonPressed="withButtonPressed" 
    @withValue="withValue"
    :isAdmin="isAdmin"></InviteView>
</template>

<script>
import InviteView from './InviteView.vue';
export default {
  name: 'employee-list-invite-row',
  components: {
    InviteView
  },
  props: {
    userId:     { type: String, default: "" },
    employeeId: { type: String, default: "" },
    isAdmin:   { type: Boolean, default: false },
  },
  methods: {
    withValue: function(event) {
      this.$emit('withValue', event);
    },
    withButtonPressed: function(event) {
      this.$emit('withButtonPressed', event);
    },
  }
}
</script>
