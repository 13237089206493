<template>
  <UpdateView 
    :userId="userId"
    @click="withButtonPressed" 
    @withButtonPressed="withButtonPressed" 
    @withValue="withValue" 
    :showButtons="showButtons"
    :isAdmin="isAdmin"></UpdateView>
</template>

<script>

import UpdateView from './UpdateView.vue';

export default {
  name: 'employee-list-update-row',
  components: {
    UpdateView
  },
  props: {
    userId: { type: String, default: "" },
    isAdmin: { type: Boolean, default: false },
    showButtons: { type: Boolean, default: false },
  },
  methods: {
    withValue: function(event) {
      this.$emit('withValue', event);
    },
    withButtonPressed: function(event) {
      this.$emit('withButtonPressed', event);
    },
  }
}
</script>
